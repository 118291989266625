/* Spinner.css */
.spinner {
    position: absolute;
 margin-top:55vh;
 margin-left: 50vw;
    z-index: 2;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    /* margin: 20px auto; */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  